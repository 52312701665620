import axios from 'axios';
import store from '@/store'
import Cookies from 'js-cookie'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import router from "../router"

const service = axios.create({
    // baseURL: 'https://yanduadmin.sendebang.com/', // VUE_API 在.env.development等文件中配置的环境变量 不同环境下的请求地址
    // baseURL: 'http://192.168.1.110/', 
    // baseURL: 'https://wlfyradmin.wlfyr.com',
    baseURL: '/pc',
    timeout: 10000, // 请求超时时间
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 每次发送请求之前判断vuex中是否存在token        
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
        // window.localStorage.setItem('sendebang', a)
        let aaa = window.localStorage.getItem('sendebang')
        // window.localStorage.removeItem('sendebang') const 
        // let aaa = '48811b77-418f-484f-ac54-25637e15ea49'
        if (aaa) {
            const token = aaa
            token && (config.headers.token = token);
            // const token = aaa
            // token && (config.headers.token = token);
        }
        // if (store.state.loginMessage.token != '') {
        //     const token = store.state.loginMessage.token
        //     token && (config.headers.token = token);
        // }
        return config;
    },
    error => {
        return Promise.error(error);
    }
)


// 响应拦截器
service.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
        // 否则的话抛出错误
        if (response.status === 200) {
            // console.log(response);
            return response.data;
        } else {
            return response.data;;
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码    
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展

    error => {
        // console.log(error.response);
        // return
        if (error.response) {
            // console.log(error.response);
            switch (error.response.status) {
                // 401: 未登录
                // 接口过期,只能进行部分操作        
                case 401:
                    // console.log(error.response, '我是401');
                    // 401的时候去掉token
                    // store.state.loginMessage.token = ''
                    if (error.response.config.url == '/api/chat/send') {
                        return
                    }
                    if (error.response.config.url == '/api/auth/logout') {
                        // window.localStorage.setItem('sendebang', a)
                        //  let aaa = window.localStorage.getItem('sendebang')
                        window.localStorage.removeItem('sendebang')
                        window.localStorage.removeItem('uid')
                        window.localStorage.removeItem("guo");
                        // Cookies.remove('sendebang');
                        return
                    }
                    if (error.response.config.url == "/api/chat/info") {
                        return
                    }
                    // window.localStorage.setItem('sendebang', a)
                    //  let aaa = window.localStorage.getItem('sendebang')
                    window.localStorage.removeItem('sendebang')
                    // Cookies.remove('sendebang');
                    // router.push({
                    //     path: store.state.path,
                    //     query:store.state.loginMessage
                    // })
                    if (error.response.config.url == "/api/user/index") {
                        return error.response.data
                    }
                    if (error.response.data.msg == "请登录后操作") {
                        Message.error(error.response.data.msg);
                        // MessageBox.confirm('当前未登录,是否转到登录页!', '系统提示', {
                        //     confirmButtonText: '确定',
                        //     cancelButtonText: '取消',
                        //     type: 'warning'
                        // }
                        // ).then(() => {
                        //     router.push('/login');
                        // }).catch((err) => {

                        // });
                    }
                    break;
                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面                
                case 403:
                    //  403错误改变
                    // 404请求不存在
                    Message.error(error.response.data.msg);
                case 404:
                    Message.error(error.response.data.msg);
                    break;
                // 其他错误，直接抛出错误提示
                default:
                    if (error.response.config.url == "/api/chat/info") {
                        return
                    }
                    Message.error(error.response.data.msg);
            }
            return Promise.reject(error.response);
        }
    }
);



export default service


