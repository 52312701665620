// 跳转方法封装
import VueRouter from "vue-router";
import Vue from "vue";
import router from "../router";
import store from '@/store'
// a 字符窜 路径
// b 对象(需要拼接的内容)
function jump(a, b) {
    store.state.loginMessage.paths = a
    store.state.loginMessage.oldpaths = router.history.current.path.replace('/', '')
    // console.log(store.state.loginMessage.oldpaths,'我');
    let c = '/' + a
    if (c == '//') {
        c = '/'
    }
    let d = {}
    Object.keys(b).forEach(key => {
        // console.log(key, b[key]);
        if (c != '/renewal') {
            if (b[key] != '') {
                if (key == 'classifNum') {
                    return
                } else if (key == 'KindNum') {
                    return
                } else if (key == 'oldpaths') {// 这层用不到
                    return
                } else if (key == 'paths') { // 这层用不到
                    return
                }else if (key == 'listkey') { // 这层用不到
                    return
                } else {
                    let names = key
                    let value = b[key]
                    d[names] = value
                }
            }

        } else {
            // 等于renewal页面时才需要那些值
            if (b[key] != '') {
                if (key == 'oldpaths') {// 这层用不到
                    return
                } else if (key == 'paths') {// 这层用不到
                    return
                } else {
                    let names = key
                    let value = b[key]
                    d[names] = value
                }

            }
        }

    });
  
    // localStorage.setItem('paths',store.state.loginMessage.paths)
    // localStorage.setItem('cardsalt', store.state.loginMessage.cardsalt)
    // localStorage.setItem('id', store.state.loginMessage.id)
    // console.log(b,'skj');


    router.push({
        path: c,
        query: d
    })
}
export default {
    jump
}