<template>
  <div id="app" ref="scrollRef">
    <router-view />
  </div>
</template>

<script>
import API from "./api/modules/app.js";
// token管理工具
import Cookies from "js-cookie";
import store from "./store";
export default {
  data() {
    return {
      gunSta: null,
      num: 0,
      // path: "wss://yanduadmin.sendebang.com/wss",//开发
      path: "wss://wlfyradmin.wlfyr.com/wss",//正式
      socket: null,
      client_id: "",
      paddnum: 0,
    };
  },
  watch: {
    client_id: function (vla, old) {
      // console.log(vla, '+++');
      this.$store.state.client_id = vla;
      // console.log(this.$store.state.client_id, '00099');
    },
  },
  created() {},
  mounted() {
    this.pvide()
    // 编辑器表情地址
    sessionStorage.setItem("di", this.$store.state.uploadurls);
    console.log('sessionStorage',sessionStorage)
    // 编辑页富文本
    sessionStorage.setItem("stu", 0);
    // 回复帖子的富文本
    sessionStorage.setItem("pingstu", 0);
    // 回复评论的富文本
    sessionStorage.setItem("cistu", 0);

    // // 获取当前页面路径
    // this.$store.state.path = this.$route.path
    // 获取地址里的信息
    let q = {};
    // 获取来自网址连接的信息
    let test = window.location.href;
    test.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (q[k] = v));
    // 全局共用页面,进入前获取相应的数据,且只执行一次
    // window.localStorage.setItem('sendebang', a)
    let aaa = window.localStorage.getItem("sendebang");
    // window.localStorage.removeItem('sendebang')
    let b = aaa;
    // this.$store.state.loginMessage = q
    this.$store.state.loginMessage.cardsalt = q.cardsalt;
    // 地址更新,更新本地存储
    // if (q.cardsalt != undefined) {
    //   localStorage.setItem("cardsalt", q.cardsalt);
    // }
    // 从链接出获取数据
    this.$store.state.loginMessage.paths = q.paths;
    this.$store.state.loginMessage.id = q.id;
    this.$store.state.loginMessage.bm = q.bm;
    this.$store.state.loginMessage.wid = q.wid;
    this.$store.state.loginMessage.key = q.key;
    this.$store.state.loginMessage.oldpaths = q.oldpaths;
    this.$store.state.loginMessage.price = q.price;
    this.$store.state.loginMessage.listkey = q.listkey;
    this.$store.state.loginMessage.compile = q.compile;
    this.$store.state.loginMessage.EditStatus = q.EditStatus;
    this.$store.state.loginMessage.classifNum = q.classifNum; //列表页如何搜素帖子
    this.$store.state.loginMessage.KindNum = q.KindNum; //列表页如何搜素帖子
    this.$store.state.loginMessage.distinguish = q.distinguish; //区分是哪里进入的
    this.$store.state.loginMessage.yaer = q.yaer; //区分是否为今年
    this.$store.state.yeming = decodeURIComponent(q.tab);
    // if (q.id != undefined) {
    //   localStorage.setItem("id", q.id);
    // }

    // if (localStorage.getItem("cardsalt") != null) {
    //   this.$store.state.loginMessage.cardsalt =
    //     localStorage.getItem("cardsalt");
    // }
    // if (localStorage.getItem("id") != null) {
    //   this.$store.state.loginMessage.id = localStorage.getItem("id");
    // }
    // this.$store.state.loginMessage.uid = q.uid
    // 获取当前时间戳
    const timess = Math.round(new Date().getTime() / 1000).toString();
    // 获取登录页里的时间戳
    let guo = window.localStorage.getItem("guo");
    if (guo != null) {
      // 判定是否超过约定时间
      if (guo <= timess) {
        // 清除token和uid
        window.localStorage.removeItem("sendebang");
        window.localStorage.removeItem("uid");
        window.localStorage.removeItem("guo");
      }
    }
    if (this.$store.state.loginMessage.paths != undefined) {
      if (this.num == 0) {
        this.num = 1;
        this.jump(
          this.$store.state.loginMessage.paths,
          this.$store.state.loginMessage
        );
      }
    }
    this.init();
    // this.info()
  },
  provide() {
    return {
      hasScrollbar: () => this.hasScrollbar(),
    };
  },
  created() {},
  methods: {
    // 判断那种上传方式
    pvide(){
      API.pvide().then(res => {
        if(res.code == 1){
          if(res.data.uploadmode == 'client'){
            this.$store.state.pvide = 1
          }else{
            this.$store.state.pvide = 0
          }
        }
      })
    },
    // 会员登录时绑定
    async ding() {
      if (window.localStorage.getItem("sendebang") == undefined) {
        return;
      }
      let res = await API.sendChat({
        toid: localStorage.getItem("uid"),
        type: "bind",
        client_id: this.client_id,
        data: "",
        port: 0,
      }).then((res) => {
        // console.log(res, "---");
        this.info();
        // if (res.code == 1) {
        //   // 获取在线状态
        //   // this.info()
        //   console.log("已重新已绑定会员");
        //   return;
        // }
      });
    },
    // 在线会员
    async info() {
      let res = await API.info().then((res) => {
        // console.log("运行11");
        this.$store.state.huiyaun = res.data;
        // if (res.code == 1) {
        //   this.$store.state.huiyaun = res.data;
        // }
      });
    },
    init() {
      let that = this;
      // WebSocket构造函数，创建WebSocket对象
      that.socket = new WebSocket(this.path);
      // 连接成功后的回调函数
      that.socket.onopen = function (params) {
        // console.log("客户端连接成功");
        // 向服务器发送消息
        // that.socket.send('hello')
      };
      // 从服务器接受到信息时的回调函数
      that.socket.onmessage = function (e) {
        // console.log('收到服务器响应', e.data)
        let a = JSON.parse(e.data);
        // console.log('外表比');
        // console.log(localStorage.getItem('uid'), '拿到了吗');
        localStorage.setItem("client", a.client_id);
        if (a.client_id != undefined) {
          that.client_id = a.client_id;
          if (localStorage.getItem("uid") != null) {
            // console.log('进入');
            that.ding();
          }
        }
      };
      // 连接关闭后的回调函数
      that.socket.onclose = function (evt) {
        // console.log("关闭客户端连接", evt);
        // that.init()
      };
      // 连接失败后的回调函数
      that.socket.onerror = function (evt) {
        // console.log("连接失败了", evt);
      };
    },

    // 判断页面是否有滚动条
    hasScrollbar() {
      return (
        document.body.scrollHeight >
        (window.innerHeight || document.documentElement.clientHeight)
      );
    },
  },
};
</script>
<style lang="scss">
#app {
  width: 100vw;
  min-width: 1300px;
  display: flex;
  justify-content: center;
  padding: 0 10px 0 0;
}

// 隐藏横向滚动条
body {
  overflow-x: hidden;
}

@media (max-width: 1300px) {
  body {
    overflow-x: auto !important;
  }
}

.gun {
  width: calc(100vw - 15px);
}
</style>
