// 首页占用接口
import request from '../request'

export default class Home {
    /**
* 提问投诉数量
* @param {*} params
* @returns
*/
    static artileNums() {
        return request.get('/api/v1.articlecurrency/conductCount')
    }
    /**
* 受理单位
* @param {*} params
* @returns
*/
    static unitList() {
        return request.get('/api/v1.department/unitList')
    }
    /**
* 帖子列表
* @param {*} params
* @returns
*/
    static articleCardList(params) {
        return request.get('/api/v1.articlecurrency/articleCardList', {
            params
        })
    }
    /**
* 搜索
* @param {*} params
* @returns
*/
    static articleSearch(params) {
        return request.get('/api/v1.articlesearch/articleSearch', {
            params
        })
    }
    /**
* 在线会员
* @param {*} params
* @returns
*/
    static info() {
        return request.get('/api/chat/info')
    }
    /**
*  今日昨日总贴数
* @param {*} params
* @returns
*/
    static allArticlenum() {
        return request.get('/api/v1.articlecurrency/allArticlenum',)
    }
    /**
     * 工作制度
     * @param {*} params
     * @returns
     */
    static zhidu() {
        return request.get('/api/info/zhidu')
    }
    /**
     * 工作职责
     * @param {*} params
     * @returns
     */
    static zhize() {
        return request.get('/api/info/zhize')
    }
    /**
     * 工作流程
     * @param {*} params
     * @returns
     */
    static liucheng() {
        return request.get('/api/info/liucheng')
    }
    /**
    * 热点受理单位列表
    * @param {*} params
    * @returns
    */
    static hotunitList() {
        return request.get('/api/v1/department/hotunitList')
    }
    /**
*  banner图
* @param {*} params
* @returns
*/
    static banner() {
        return request.get('/api/v1/newsrelease/banner')
    }
    /**
*  热点聚焦
* @param {*} params
* @returns
*/
    static focusArticle() {
        return request.get('/api/v1/articlecurrency/focusArticle')
    }
    /**
*  后台位置
* @param {*} params
* @returns
*/
    static houurl() {
        return request.get('/api/info/url')
    }
}
