// 判断有没有token,没有让他去登录

import VueRouter from "vue-router";
import Vue from "vue";
import store from '@/store'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import Cookies from 'js-cookie'
import router from "../router"
// a 当前页面路径
function verification(a) {
    // window.localStorage.setItem('sendebang', a)
    let aaa = window.localStorage.getItem('sendebang')
    // window.localStorage.removeItem('sendebang')
    if (aaa == undefined) {
        MessageBox.confirm('需要登录后才可以操作,是否转到登录页!', '系统提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }
        ).then(() => {
            store.state.path = a
            router.push({
                path: '/login',
                query: {}
            })
        }).catch((err) => {

        });
        return false
    }
}
export default {
    verification
}