// app.vue页面专用接口
import request from '../request'

export default class App {
    static user() {
        return request.get("/api/user/index")
    }
    static token() {
        return request.get("/api/token/check")
    }
    /**
* 会员登录绑定信息
* @param {*} params
* @returns
*/
    static sendChat(data) {
        return request.post('/api/chat/send', data)
    }
    /**
* 在线会员
* @param {*} params
* @returns
*/
    static info() {
        return request.get('/api/chat/info')
    }
    /**
*  阿里云oss存储
* @param {*} params 
* @returns 
*/
    static alioss() {
        return request.post("/api/alioss/index")
    }
    /**
*  判断使用那种上传视频方式
* @param {*} params 
* @returns 
*/
    static pvide() {
        return request.get("/api/index/init")
    }
}