import router from "../router";
import store from '@/store'

function jumpDel(a, b) {
    let moren = 99
    if(a != undefined){
        moren = a
    }
    // 清空连接
    // var old_url = window.location.href;
    // var new_url = old_url.substring(0, old_url.indexOf('?'));
    // self.location = new_url
    // 清空对象
    store.state.loginMessage = { //登录信息
        cardsalt:'',//帖子详情
        paths:'',//当前页面路径
        id:'',
        bm:'',//部门id
        wid:'',//文章id
        // bmid:'',//热点单位里的部门id
        oldpaths:'',//过去的地址
        key:'',//网信和网安
        price:0,//首页顶部选值
        listkey:moren,//类别:0=咨询,1=投诉,2=建议,3=全部
        compile:'',//编辑id
        EditStatus:0,//编辑状态
        classifNum:0,//列表页如何搜素帖子
        KindNum:1,//列表页如何搜素帖子
        distinguish:0,//区分是哪里进入的
        yaer:0,//热门单位是否为今年0否 1是
        tab:'',
    }

}
export default {
    jumpDel
}

