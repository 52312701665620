<!-- 顶部导航栏 -->
<template>
  <div class="w-full h-full _gn">
    <div class="zx">
      <!-- 左 -->
      <div class="flex">
        <div class="mr7 fontOne">
          {{
            "您好，欢迎进入盐都区网络发言人公共服务平台 (盐都区公众监督网络服务平台)"
          }}
        </div>
      </div>
      <!-- 右 -->
      <div class="flex">
        <div class="mr7 fontOne flex align-items" v-if="user == ''">
          <el-popover placement="top" v-model="visible" trigger="click">
            <div>
              <div class="gzh">
                <div>微信小程序二维码</div>
              </div>
              <div style="width: 150px; height: 150px; margin: 0">
                <!-- https://yanduadmin.sendebang.com/assets/image/code.jpg -->
                <img
                  :src="$store.state.uploadurls + '/assets/image/code.jpg'"
                  style="width: 150px; height: 150px"
                  alt=""
                />
              </div>
            </div>
            <div slot="reference" class="ziti flex align-items">
              <div class="weixin">
                <img src="../../assets/img/one/weixin.png" alt="" />
              </div>
              <div>小程序二维码</div>
            </div>
          </el-popover>
          <div @click="denglu" style="margin-left: 10px">{{ "登录" }}</div>
          <!-- <div>{{ '注册' }}</div> -->
        </div>
        <div v-else class="flex align-items">
          <el-popover placement="top" v-model="visible" trigger="click">
            <div>
              <div class="gzh">
                <div>微信小程序二维码</div>
              </div>
              <div style="width: 150px; height: 150px; margin: 0">
                <!-- https://yanduadmin.sendebang.com/assets/image/code.jpg -->
                <img
                  :src="$store.state.uploadurls + '/assets/image/code.jpg'"
                  style="width: 150px; height: 150px"
                  alt=""
                />
              </div>
            </div>
            <div slot="reference" class="ziti flex align-items">
              <div class="weixin">
                <img src="../../assets/img/one/weixin.png" alt="" />
              </div>
              <div>小程序二维码</div>
            </div>
          </el-popover>
          <div class="flex align-items hovv" style="margin-left: 10px">
            <el-badge :value="num" class="item" :hidden="num == 0">
              <img
                src="../../assets/img/xinban/xiaoxi.png"
                @click="message"
                class="icon"
                alt=""
              />
            </el-badge>
            <div class="ziti" @click.stop="message">{{ "消息提示" }}</div>
          </div>
          <div class="shuxian"></div>
          <div class="touxiang">
            <img
              :src="user.netizen_avatar"
              v-if="user.netizen_avatar != ''"
              class="w-full h-full"
              alt=""
            />
            <img
              src="../../assets/img/one/weizhiyonghu.png"
              v-else
              class="w-full h-full"
              alt=""
            />
          </div>
          <div class="name zuoyou" @click="geren">
            {{ user.netizen_nickname }}的留言管理
          </div>
          <div class="zuoyou" @click="shzhi">{{ "账号设置" }}</div>
          <div class="shuxian jianjus"></div>
          <div class="tuichu" @click="tuichu">{{ "退出" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入api
import API from "@/api/modules/heads.js";
import Cookies from "js-cookie";
import Bus from "@/assets/ligature";
export default {
  data() {
    return {
      user: "", //用户头像信息
      num: 0, //消息总个数
      visible: false,
    };
  },
  async mounted() {
    // window.localStorage.setItem('sendebang', a)
    let aaa = window.localStorage.getItem("sendebang");
    // window.localStorage.removeItem('sendebang')
    if (aaa != undefined) {
      this.yonghu();
      this.geshu();
    }
  },
  created() {
    // 搜素关键字:头像刷新
    Bus.$on("yonghuxinxi", () => {
      this.yonghu();
    });
    // 搜索关键字:消息刷新
    Bus.$on("xioaxi", () => {
      this.geshu();
    });
  },
  // localStorage.getItem('client')
  methods: {
    // 进入修改信息页面
    shzhi() {
      this.jumpDel();
      this.$store.state.shezhi = 1;
      this.$store.state.loginMessage.listkey = 99;
      this.$store.state.loginMessage.key = 0;
      if (this.$route.path == "/persomal") {
        //如果在当前页面则通知当前页面切换
        // 关键词:互切换 0
        Bus.$emit("hqieh", "1");
        return;
      }
      this.jumps("persomal", this.$store.state.loginMessage);
      // this.jumps('editing', this.$store.state.loginMessage)
    },
    // 会员登录时解绑
    async ding() {
      let res = await API.sendChat({
        toid: "",
        type: "unbind",
        client_id: localStorage.getItem("client"),
        data: "",
        port: 0,
      }).then((res) => {
        // console.log(res, "---");
        if (res.code == 1) {
          // 获取在线状态
          // console.log("已经解绑");
        }
      });
    },
    // 个人
    geren() {
      this.jumpDel();
      this.$store.state.shezhi = 0;
      this.$store.state.loginMessage.listkey = 99;
      this.$store.state.loginMessage.key = 0;
      if (this.$route.path == "/persomal") {
        //如果在当前页面则通知当前页面切换
        // 关键词:互切换 0
        Bus.$emit("hqieh", "0");
        return;
      }
      this.jumps("persomal", this.$store.state.loginMessage);
    },
    // 进入消息中心
    message() {
      this.jumpDel();
      // 初始化咨询信息
      this.$store.state.loginMessage.listkey = 99;
      this.$store.state.loginMessage.key = 0;
      this.jumps("message", this.$store.state.loginMessage);
    },
    // 获取个人信息
    async yonghu() {
      let res = await API.user().then((res) => {
        if (res.code == 1) {
          this.user = res.data;
        }
      });
    },
    // 获取消息个数
    async geshu() {
      // console.log('223333');
      let res = await API.messageCount().then((res) => {
        if (res.code == 1) {
          // this.num = res.data.allcount + res.data.comcount + res.data.likescomcount + res.data.syscount
          this.num = res.data.allcount;
          this.$store.state.sys = res.data.syscount;
          this.$store.state.zhut = res.data.themecount;
          this.$store.state.sz = res.data.likescomcount;
          this.$store.state.fs = res.data.followcount;
          this.$store.state.sf = res.data.comcount;
        }
      });
    },
    // 返回登录页
    denglu() {
      // 删除cooking
      // window.localStorage.setItem('sendebang', a)
      // let aaa = window.localStorage.getItem('sendebang')
      window.localStorage.removeItem("sendebang");
      // Cookies.remove("sendebang");
      this.ding();
      // this.$router.push({
      //     path: `/login`,
      //     query: this.$store.state.loginMessage
      // })
      // console.log(this.$store.state.loginMessage);
      // this.$store.state.loginMessage.paths = "login";
      this.$router.push("login", { query: {} });
      // this.jump("login", this.$store.state.loginMessage);
    },
    // 退出键
    async tuichu() {
      // 解绑会员
      await API.sendChat({
        toid: localStorage.getItem("uid"),
        type: "unbind",
        client_id: localStorage.getItem("client"),
        data: "",
        port: 0,
      });
      // token无效化
      await API.logout();

      // 清空数据
      // window.localStorage.setItem('sendebang', a)
      // let aaa = window.localStorage.getItem('sendebang')
      window.localStorage.removeItem("sendebang");
      // Cookies.remove("sendebang");
      // this.$store.state.loginMessage,
      this.$router.push({
        path: `/login`,
        query: {},
      });
    },
    // 进入设置页面
    shehzi() {
      if (this.$route.path == "/issue") {
        return;
      }
    },
    // 进入消息页面
    xiaoxi() {
      if (this.$route.path == "/information") {
        return;
      }
      this.$router.push("/information");
    },
  },
};
</script>

<style lang="scss" scoped>
._gn {
  display: flex;
  justify-content: center;
  background: #ffffff;
}

.zx {
  width: 1200px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fontOne {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #717a8d;
  letter-spacing: 0;
  text-align: right;
  font-weight: 400;
  // cursor: pointer;
}

.mr7 {
  margin-right: 7px;
}

.mr30 {
  margin-right: 30px;
}

.icon {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.name {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #717a8d;
  letter-spacing: 0;
  font-weight: 400;
}
.zuoyou {
  margin: 0 10px 0 10px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #717a8d;
  letter-spacing: 0;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: #de1b1b;
  }
}
.hovv:hover {
  .ziti {
    color: #de1b1b;
  }
}
.ziti {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #717a8d;
  letter-spacing: 0;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 1px;
  margin-left: 3px;
}
.shuxian {
  width: 1px;
  height: 15px;
  border-right: 1px solid #b8b8b8;
  margin: 0 0px 0 10px;
}
.touxiang {
  width: 32px;
  height: 32px;
  // margin: 0 21px 0 5px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  margin-left: 10px;
}

.tuichu {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #717a8d;
  letter-spacing: 0;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: #de1b1b;
  }
}
.jianjus {
  margin: 0 10px 0 0px;
}
.gzh {
  text-align: center;
  margin-bottom: 5px;
  font-size: 15px;
  align-items: center;
}
.weixin {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  img {
    width: 32px;
    height: 32px;
  }
}
</style>