<template>
  <div
    :class="dianji == 2 || dianji == 3 ? 'buji1' : 'buju'"
    class="app-container canvasImgObj"
  >
    <div class="zuoding" @click="huiding" v-if="gunnum != 0">
      <img src="../assets/img/xinban/dingbu.png" alt="" />
    </div>
    <!-- <div class="jieping" @click="GenerateImg">截屏</div> -->
    <!-- <div :class="{ dian: dianji == 2, dian: dianji == 3 }"></div> -->
    <div :class="{ bg: dianji == 2 || dianji == 3 }"></div>
    <div :class="{ fu: dianji == 2 || dianji == 3 }">
      <!-- 顶部功能栏 -->
      <div class="gn">
        <Heas></Heas>
      </div>
      <!-- 顶部不动部分 -->
      <div class="logo w-full">
        <!-- logo -->
        <div class="yiyan" :class="{ maeg: shounum == 0 }">
          <div class="iconone">
            <img
              src="../assets/img/new/logo.png"
              class="w-full h-full"
              alt=""
            />
          </div>
          <div class="icontwo">
            <img
              src="../assets/img/new/biaoti.png"
              class="w-full h-full"
              alt=""
            />
          </div>
        </div>
        <!-- 搜索框 -->
        <div class="shurukuang" v-if="shounum != 0">
          <div class="sousuos">
            <input
              type="text"
              class="kkdabu"
              placeholder="请输入您要搜索的内容"
              v-model="sosusuok"
              @keydown="handleFocus"
              :maxlength="50"
            />
            <div class="xiala">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="fangdaj" @click="shuchu">
              <img src="../assets/img/new/shang.png" class="shizhe" alt="" />
            </div>
          </div>
        </div>
        <!-- 切换标签 -->
        <div class="qieh">
          <div
            @click="qiehuan(item.id)"
            :class="{ xiaot: tnum == item.id }"
            class="ge"
            v-for="(item, index) in list"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="neirong">
      <!-- 版心 -->
      <div class="banxin" :class="{ mt: dianji == 2 || dianji == 3 }">
        <div class="w-full h-full flex justify-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <!-- 版权 -->
    <div class="banquan">
      <div class="Done flex"></div>
      <div class="Dfour">
        <span class="dazi" @click="bean">{{
          "苏公网安备32090302000413号 "
        }}</span>
      </div>
      <div class="Dfour cursor_pointer" @click="bei">
        {{ "苏ICP备2023026231号-1" }}
      </div>
      <!-- <div class="Dtwo">
        {{
          "Copyright @2023-2024 盐都区网络发言人公共服务平台 (https://www.wlfyr.com)版权所有."
        }}
      </div> -->
      <div class="Dtwo">
        <span>Copyright @2023-2024 </span>
        <span class="hove" @click="houdizhi">盐都区公众监督网络服务平台</span>
        <span> (</span>
        <span>https://www.wlfyr.com</span>
        <span>)版权所有.</span>
      </div>
      <div class="Dthree">
        {{
          "免责声明：本网不承担任何由内容提供商提供的信息所引起的争议和法律责任."
        }}
      </div>
    </div>
  </div>
</template>

<script>
import Heas from "./heads/index.vue"; // 头部导航栏
import Bus from "@/assets/ligature";
// 转图

import html2canvas from "html2canvas";

import API from "@/api/modules/home.js";
export default {
  components: {
    Heas,
  },
  data() {
    return {
      options: [
        {
          value: "0",
          label: "帖子",
        },
        {
          value: "1",
          label: "动态",
        },
      ],
      gunnum: 0,
      value: "0",
      shounum: 1, //是否为首页
      tnum: 6, //top切换
      daoNum: 0, //导航栏移入移出事件
      dianji: 1, // 导航栏点击事件
      list: [
        {
          name: "首页",
          id: 0,
        },
        {
          name: "咨询信息",
          id: 1,
        },
        {
          name: "建议信息",
          id: 7,
        },
        {
          name: "投诉信息",
          id: 2,
        },
        {
          name: "精选回复",
          id: 8,
        },
        {
          name: "热点聚焦",
          id: 3,
        },
        {
          name: "网信动态",
          id: 4,
        },
        {
          name: "网安知识",
          id: 5,
        },
        {
          name: "信息举报",
          id: 9,
        },
        {
          name: "便民信息",
          id: 6,
        },
      ],
      houtai: "",
      sosusuok: "",
    };
  },
  watch: {
    $route: function (to, from) {
      this.shounum = 0;
      this.sosusuok = ''
      // console.log(to, "数据");
      if (to.path == "/") {
        this.daoNum = 1;
        this.dianji = 1;
        this.tnum = 0;
        this.shounum = 1;
      } else if (to.path == "/leaveword") {
        this.dianji = 2;
        this.daoNum = 2;
      } else if (to.path == "/satisfaction") {
        this.dianji = 3;
        this.daoNum = 3;
      } else if (to.path == "/harmful") {
        this.dianji = 4;
        this.daoNum = 4;
        this.tnum = 9;
      } else if (to.path == "/infos") {
        this.dianji = 5;
        this.daoNum = 5;
      } else if (to.path == "/choiceness") {
        this.tnum = 8;
      } else if (to.path == "/hotfocus") {
        this.tnum = 3;
      } else if (to.path == "/renewalz") {
        // 监听到列表页
        this.tnum = to.query.price;
      } else if (to.path == "/renewalj") {
        // 监听到列表页
        this.tnum = to.query.price;
      } else if (to.path == "/renewalt") {
        // 监听到列表页
        this.tnum = to.query.price;
      } else if (to.path == "/infoslist") {
        // 监听网安
        this.tnum = to.query.price;
        // 搜素关键字:返回监听
      } else if (to.path == "/infoslistt") {
        // 监听网信
        this.tnum = to.query.price;
      } else if (to.path == "/infoslists") {
        // 监听网信
        this.tnum = to.query.price;
      } else if (to.path == "/infoslistx") {
        // 监听网信
        this.tnum = to.query.price;
      } else {
        this.daoNum = 1;
        this.dianji = 1;
        this.tnum = to.query.price
      }
      // 监听路径不是网安和网信就key值归0
      if (to.path != "/infoslist") {
        this.$store.state.loginMessage.key = 0;
      }
    },
  },
  mounted() {
    //监听滚动条滚动事件
    window.addEventListener("scroll", this.listenerScroll, true);
    let currentRoute = this.$router.currentRoute;
    if (currentRoute.fullPath == "/") {
      this.shounum = 1;
    } else {
      this.shounum = 0;
    }
    if (this.$store.state.loginMessage.price == undefined) {
      this.tnum = 0;
    } else {
      this.tnum = this.$store.state.loginMessage.price;
    }


    if (this.$route.path == "/") {
      this.daoNum = 1;
      this.dianji = 1;
    } else if (this.$route.path == "/leaveword") {
      this.dianji = 2;
      this.daoNum = 2;
    } else if (this.$route.path == "/satisfaction") {
      this.dianji = 3;
      this.daoNum = 3;
    } else if (this.$route.path == "/harmful") {
      this.dianji = 4;
      this.daoNum = 4;
    } else if (this.$route.path == "/infos") {
      this.dianji = 5;
      this.daoNum = 5;
    } else {
      this.daoNum = 1;
      this.dianji = 1;
    }
    this.houurl();
  },
  created() {
    // 搜素关键字:进入热点聚焦
    Bus.$on("redianjujiao", () => {
      this.qiehuan(3);
    });
  },
  methods: {
    //滚动条改变触发该函数
    listenerScroll() {
      let value =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (value > 100) {
        this.gunnum = 1;
      } else {
        this.gunnum = 0;
      }
    },
    // 回到顶部
    huiding() {
      window.scrollTo({
        top: 0, // 滚动到页面顶部
        behavior: "smooth", // 平滑滚动
      });
    },
    shuchu() {
      let a = {
        stu: this.value,
        hunt: this.sosusuok,
      };
      localStorage.setItem("sou", JSON.stringify(a));
      this.jump("SearchContent", this.$store.state.loginMessage);
    },
    // 输入框聚焦监听
    handleFocus(event) {
      if (event.key === "Enter") {
        let a = {
          stu: this.value,
          hunt: this.sosusuok,
        };
        localStorage.setItem("sou", JSON.stringify(a));
        // 处理Enter键的逻辑
        this.jump("SearchContent", this.$store.state.loginMessage);
      }
    },
    houdizhi() {
      if (this.houtai == "") {
        // this.$message.error("");
        return;
      }
      window.open(this.houtai, "_blank");
    },
    // 获取权限接口
    houurl() {
      let res = API.houurl().then((res) => {
        if (res.code == 1) {
          this.houtai = res.data;
        }
      });
    },
    bean() {
      window.open(
        " http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32090302000413",
        "_blank"
      );
    },
    bei() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
    //转换成图片
    GenerateImg() {
      let canvas = document.querySelector(".canvasImgObj"); //先获取要截取页面的DOM
      let that = this;
      html2canvas(canvas, { scale: 2, logging: false, useCORS: true }).then(
        function (canvas) {
          let type = "png";
          let imgData = canvas.toDataURL(type);
          // 照片格式处理
          let _fixType = function (type) {
            type = type.toLowerCase().replace(/jpg/i, "jpeg");
            let r = type.match(/png|jpeg|bmp|gif/)[0];
            return "image/" + r;
          };
          imgData = imgData.replace(_fixType(type), "image/octet-stream");
          let filename = "设备地图" + "." + type;
          that.saveFile(imgData, filename);
        }
      );
    },
    saveFile(data, filename) {
      let save_link = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
      );
      save_link.href = data;
      save_link.download = filename;
      let event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      save_link.dispatchEvent(event);
    },

    // 跳入编写
    xie(num) {
      this.$store.state.leiixng = num;
      this.jumps("issue", this.$store.state.loginMessage);
    },

    // 重新通知刷新
    chongi() {
      this.jump("infoslist", this.$store.state.loginMessage);
    },
    // 导航栏点击事件
    qiehuan(num) {
      this.jumpDel();
      this.tnum = num;
      this.$store.state.loginMessage.price = num;
      if (num == 0) {
        if (this.$route.path == "/") {
          return;
        }
        this.$router.push("/");
      } else if (num == 1) {
        // ---咨询信息
        this.$store.state.yeming = "咨询信息";
        this.jump("renewalz", this.$store.state.loginMessage);
      } else if (num == 2) {
        // ---投诉信息
        this.$store.state.yeming = "投诉信息";
        this.jump("renewalt", this.$store.state.loginMessage);
      } else if (num == 3) {
        // 热点聚焦
        this.jump("hotfocus", this.$store.state.loginMessage);
      } else if (num == 4) {
        // ==网信动态
        this.$store.state.yeming = "网信动态";
        this.jump("infoslistt", this.$store.state.loginMessage);
      } else if (num == 5) {
        // ==网安知识
        this.$store.state.yeming = "网安知识";
        this.jump("infoslists", this.$store.state.loginMessage);
      } else if (num == 6) {
        // ==便民政策
        this.$store.state.yeming = "便民政策";
        this.jump("infoslistx", this.$store.state.loginMessage);
      } else if (num == 7) {
        // ---建议信息
        this.$store.state.yeming = "建议信息";
        this.jump("renewalj", this.$store.state.loginMessage);
      } else if (num == 8) {
        // 精选回复
        this.jump("choiceness", this.$store.state.loginMessage);
      } else if (num == 9) {
        //信息举报
        this.jump("harmful", this.$store.state.loginMessage);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buju {
  width: 100%;
  // min-width: 1300px;
  // background-image: url('../assets/img/xinban/bj.png');
  // background-size: 100% 560px;
  // background-position: 0px 48px;
  // background-repeat: no-repeat;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  background-image: url("../assets/img/xinban/bj.png");
  background-size: 100% 556px;
  background-repeat: no-repeat;
}

.buju1 {
  width: 100%;
  // min-width: 1300px;
  // background-image: url('../assets/img/xinban/bj.png');
  // background-size: 100% 560px;
  // background-position: 0px 48px;
  // background-repeat: no-repeat;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  // background-image: url("../assets/img/xinban/bj.png");
  // background-size: 100% 556px;
  // background-repeat: no-repeat;
}

.gn {
  width: 100%;
  height: 48px;
  // position: fixed;
}

.logo {
  display: flex;
  background: #064f9d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 31px 0 0px 0;
  box-sizing: border-box;
  .ziti {
    width: 1200px;
    height: 100%;
    position: relative;

    .wenzi {
      position: absolute;
      top: 28%;
      left: 0;
      width: 620px;
      // height: 50px;
    }
  }

  .ban {
    width: 1200px;
    font-weight: 700;
    justify-content: space-between;
  }

  .biaoti {
    border: 0px solid rgba(159, 199, 246, 0.51);
    font-family: PingFangSC-Semibold;
    font-size: 42px;
    color: #ffffff;
    letter-spacing: -0.2px;
    text-shadow: 0 4px 4px #05044b;
    font-weight: 600;
    user-select: none;
  }

  .wangzhi {
    border: 0px solid rgba(255, 255, 255, 0.51);
    font-family: PingFangSC-Semibold;
    font-size: 26px;
    color: #03479b;
    letter-spacing: 0;
    text-shadow: 0 4px 4px #ffffff;
    font-weight: 600;
    margin: 0 0 53px 0;
  }
}

.neirong {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 48px - 239px - 130px);
  .banxin {
    width: 100%;
    min-width: 1300px;
    display: flex;
    flex-direction: column;
  }
}

.heads {
  width: 100%;
  height: 52px;
  font-family: "Microsoft YaHei";
  background: #ffffff;
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #015293;
  letter-spacing: 0;
  font-weight: 500;
  display: flex;
  justify-content: center;

  .daoh {
    flex: 1;
    font-weight: 700;
    padding: 0 40px;
    height: 100%;
    line-height: 52px;
    // padding: 0 18px;
    cursor: pointer;
    user-select: none;
    text-align: center;
    position: relative;

    .jiantou {
      position: absolute;
      bottom: 5px;
      left: calc(50% - 5px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #fff transparent transparent transparent;
    }
  }

  .ban {
    width: 1200px;
  }
}

// 版权
.banquan {
  width: 100vw;
  min-width: 1300px;
  clear: both;
  padding: 20px 0 0px 0;
  background: #151d26;
  height: 150px;
  display: flex;
  align-items: center;
  // margin-top: 23px;
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #a5a7a7;
  letter-spacing: 0;
  font-weight: 400;
  flex-direction: column;

  .Dtwo {
    margin-top: 5px;
  }

  .Dthree {
    margin-top: 5px;
  }

  .Dfour {
    margin-top: 5px;
  }
}

.fontTwo {
  font: 12px/1.5 "Microsoft Yahei", "Simsun";
  color: #444;
}

.mr7 {
  margin-right: 7px;
}

.act {
  background: #3eacdd !important;
  color: #ffffff;
}

.actio {
  background: #3eacdd !important;
  color: #ffffff;
}

.fu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.dian {
  width: 100%;
  height: calc(48px + 213px + 52px);
}

.mt {
  margin-top: calc(48px + 213px + 52px + 59px);
}

.bg {
  width: 100%;
  height: 556px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  background-image: url("../assets/img/xinban/bj.png");
  background-size: 100% 556px;
  background-repeat: no-repeat;
}
.yiyan {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  padding-right: 23px;
}
.maeg {
  margin-bottom: 19px !important;
}
.iconone {
  width: 92.87px;
  height: 92px;
  margin-right: 20px;
}
.icontwo {
  width: 690px;
}
.qieh {
  width: 1200px;
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
}
.ge {
  flex: 1;
  text-align: center;
  line-height: 60px;
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #034899;
  letter-spacing: 0;
  font-weight: 600;
  cursor: pointer;
}
.xiaot {
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 600;
  background-image: url("../assets/img/new/top.png");
  background-size: 100% 100%;
  border-radius: 4px;
  overflow: hidden;
}
.guan {
  width: 1200px;
  height: 145px;
  margin-bottom: 16px;
}
.why {
  width: 1200px;
  height: 166px;
  border-radius: 4px;
  background-image: url("../assets/img/new/dise.png");
  background-size: 100% 100%;
  padding: 28px 0 25px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.fang {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: PingFangSC-Semibold;
  font-size: 22px;
  color: #0b52b6;
  font-weight: 600;
  .icon {
    width: 68px;
    height: 68px;
    margin-bottom: 13px;
  }
  cursor: pointer;
}
.xixian {
  width: 1px;
  height: 60px;
  border-left: 1px solid #9bafc5;
}
.jieping {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 20;
  cursor: pointer;
}
.dazi {
  cursor: pointer;
}
.hove:hover {
  cursor: pointer;
  color: #66ccff;
}
.shurukuang {
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
  .sousuos {
    width: calc(638px - 94.29px);
    height: 60px;
    background: rgba(250, 250, 250, 0.28);
    display: flex;
    position: relative;
    .kkdabu {
      width: 85%;
      height: 100%;
      border: 1px solid #eae8e8;
      border-radius: 4px;
      background: transparent;
      outline: none;
      padding-left: 33px;
      font-family: PingFangSC-Regular;
      font-size: 22px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      padding-right: 33px;
      border-right: 0px solid;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .kkdabu::placeholder {
      font-family: PingFangSC-Regular;
      font-size: 22px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
    }
    .xiala {
      flex: 1;
      border: 1px solid #eae8e8;
      border-radius: 4px;
      border-left: 0px solid;
      height: 60px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      ::v-deep(.el-input__inner) {
        background-color: transparent !important;
        border: 0px solid #dcdfe6 !important;
        font-family: PingFangSC-Regular;
        font-size: 18px !important;
        color: #ffffff !important;
        font-weight: 400;
      }
    }
  }
  .fangdaj {
    position: absolute;
    top: 0.5px;
    width: 94.29px;
    height: 59px;
    right: -92.29px;
    z-index: 10;
    background-image: linear-gradient(114deg, #88e4b6 0%, #306ef5 100%);
    border-radius: 0 4px 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .shizhe {
      width: 33.78px;
      height: 34.88px;
    }
  }
}
.zuoding {
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 100;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
  }
}
</style>