import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/layout.vue'
import Cookies from 'js-cookie'
import store from '@/store'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '盐都区网络发言人公众服务平台 (盐都区公众监督网络服务平台)'
    },
    component: Home,
    meta: { keepAlive: true },
    children: [
      {
        path: '/',
        name: 'home',
        meta: {
          title: '盐都区网络发言人公众服务平台 (盐都区公众监督网络服务平台)'
        },
        component: () => import('@/components/firstPage/index.vue')
      },
      // 最新更新
      {
        path: '/renewal',
        name: 'renewal',
        meta: {
          title: ''
        },
        component: () => import('@/components/renewal/index.vue')
      },
      {  // 要求点击返回要求回到上一页,但这个页面本身是同一个页面参数不同,vue会报错,所拆成三个相同的页面
        // 或许有其他方法,但这个方法简单快捷
        path: '/renewalz',
        name: 'renewalz',
        meta: {
          title: '咨询信息'
        },
        component: () => import('@/components/renewal/sanbao/renewalz')
      },
      { // 要求点击返回要求回到上一页,但这个页面本身是同一个页面参数不同,vue会报错,所拆成三个相同的页面
        // 或许有其他方法,但这个方法简单快捷
        path: '/renewalj',
        name: 'renewalj',
        meta: {
          title: '建议信息'
        },
        component: () => import('@/components/renewal/sanbao/renewalj')
      },
      { // 要求点击返回要求回到上一页,但这个页面本身是同一个页面参数不同,vue会报错,所拆成三个相同的页面
        // 或许有其他方法,但这个方法简单快捷
        path: '/renewalt',
        name: 'renewalt',
        meta: {
          title: '投诉信息'
        },
        component: () => import('@/components/renewal/sanbao/renewalt')
      },
      // 最新更新(按钮路径:首页下面的查看更多)
      {
        path: '/renewals',
        name: 'renewals',
        meta: {
          title: '最新更新'
        },
        component: () => import('@/components/renewals/index.vue')
      },
      // 热点聚焦
      {
        path: '/hotfocus',
        name: 'hotfocus',
        meta: {
          title: '热点聚焦'
        },
        component: () => import('@/components/hotfocus/index.vue')
      },
      // 精选回复
      {
        path: '/choiceness',
        name: 'choiceness',
        meta: {
          title: '精选回复'
        },
        component: () => import('@/components/choiceness/index.vue')
      },
      // 搜索内容
      {
        path: '/SearchContent',
        name: 'SearchContent',
        meta: {
          title: '搜索'
        },
        component: () => import('@/components/SearchContent/index.vue')
      },
      // 发布主题
      {
        path: '/issue',
        name: 'issue',
        meta: {
          title: '我要发帖'
        },
        component: () => import('@/components/issue/index.vue')
      },
      // 详情
      {
        path: '/details',
        name: 'details',
        meta: {
          title: '详情'
        },
        component: () => import('@/components/details/index.vue')
      },
      // 文明实践详情
      {
        path: '/civilizeddetails',
        name: 'civilizeddetails',
        meta: {
          title: ''
        },
        component: () => import('@/components/wendetails/index.vue')
      },
      // 个人资料
      {
        path: '/persomal',
        name: 'persomal',
        meta: {
          title: ''
        },
        component: () => import('@/components/personal/index.vue')
      },
      // 消息中心
      {
        path: '/message',
        name: 'message',
        meta: {
          title: '消息中心'
        },
        component: () => import('@/components/message/index.vue')
      },
      // 他人资料
      {
        path: '/other',
        name: 'other',
        meta: {
          title: ''
        },
        component: () => import('@/components/other/index.vue')
      },
      // 编辑个人资料
      {
        path: '/editing',
        name: 'editing',
        component: () => import('@/components/editing/index.vue')
      },
      // 文明实践civilized
      {
        path: '/civilized',
        name: 'civilized',
        meta: {
          title: '文明实践'
        },
        component: () => import('@/components/civilized/index.vue')
      },
      // 留言统计
      {
        path: '/leaveword',
        name: 'leaveword',
        meta: {
          title: '留言统计'
        },
        component: () => import('@/components/leaveword/index.vue')
      },
      // 啄木鸟平台
      {
        path: '/woodpecker',
        name: 'woodpecker',
        meta: {
          title: '啄木鸟'
        },
        component: () => import('@/components/woodpecker/index.vue')
      },
      // 未审核的详情
      {
        path: '/detail',
        name: 'detail',
        meta: {
          title: ''
        },
        component: () => import('@/components/detail/index.vue')
      },
      // 未通过审核和隐藏的帖子详情
      {
        path: '/notpass',
        name: 'notpass',
        meta: {
          title: '未通过审核'
        },
        component: () => import('@/components/weidetails/index.vue')
      },
      // 回复满意度统计
      {
        path: '/satisfaction',
        name: 'satisfaction',
        meta: {
          title: '回复满意度统计'
        },
        component: () => import('@/components/satisfaction/index.vue')
      },
      // 部门列表
      {
        path: '/unit',
        name: 'unit',
        meta: {
          title: '单位查询'
        },
        component: () => import('@/components/unit/index.vue')
      },
      // 有害信息举报
      {
        path: '/harmful',
        neme: 'harmful',
        meta: {
          title: '有害信息举报'
        },
        component: () => import('@/harmful/index.vue')
      },
      // 信息动态
      {
        path: '/infos',
        neme: 'infos',
        component: () => import('@/infos/index.vue')
      },
      // 信息动态-列表页
      {
        path: '/infoslist',
        neme: 'infoslist',
        meta: {
          title: ''
        },
        component: () => import('@/components/infolist/index.vue')
      },
      {// 要求点击返回要求回到上一页,但这个页面本身是同一个页面参数不同,vue会报错,所拆成三个相同的页面
        // 或许有其他方法,但这个方法简单快捷
        path: '/infoslistt',
        neme: 'infoslistt',
        meta: {
          title: '网信动态'
        },
        component: () => import('@/components/infolist/sanbao/infoslistt.vue')
      },
      {// 要求点击返回要求回到上一页,但这个页面本身是同一个页面参数不同,vue会报错,所拆成三个相同的页面
        // 或许有其他方法,但这个方法简单快捷
        path: '/infoslists',
        neme: 'infoslists',
        meta: {
          title: '网安知识'
        },
        component: () => import('@/components/infolist/sanbao/infoslists.vue')
      },
      {// 要求点击返回要求回到上一页,但这个页面本身是同一个页面参数不同,vue会报错,所拆成三个相同的页面
        // 或许有其他方法,但这个方法简单快捷
        path: '/infoslistx',
        neme: 'infoslistx',
        meta: {
          title: '便民信息'
        },
        component: () => import('@/components/infolist/sanbao/infoslistx.vue')
      },
      // 信息动态-详情页
      {
        path: '/infosdetail',
        neme: 'infosdetail',
        meta: {
          title: '文章详情'
        },
        component: () => import('@/infosdetail/index.vue')
      }
    ]
  },
  // 登录页
  {
    path: '/login',
    neme: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/logn/index.vue')
  },
  // 注册页
  {
    path: '/enroll',
    neme: 'enroll',
    meta: {
      title: '注册'
    },
    component: () => import('@/logn/zhuce/index.vue')
  },
  // 注册页
  {
    path: '/bind',
    neme: 'bind',
    meta: {
      title: '绑定用户'
    },
    component: () => import('@/logn/bind/index.vue')
  },
  // 协议
  {
    path: '/agreement',
    neme: 'agreement',
    meta: {
      title: ''
    },
    component: () => import('@/logn/xieyi/index.vue')
  },
  // 忘记密码
  {
    path: '/forget',
    neme: 'forget',
    meta: {
      title: '忘记密码'
    },
    component: () => import('@/logn/wangji/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history', // 正式
  base: '/pc', //正式
  // model: 'hash', // 开发
  // base: '/new',

  routes
})
// 路由守卫
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
  // 获取token
  // window.localStorage.setItem('sendebang', a)
  let aaa = window.localStorage.getItem('sendebang')
  // window.localStorage.removeItem('sendebang')
  let a = aaa
  if (to.path == '/login') {
    if (a != null) {
      router.push('/')
    }
  }
  if (to.path == '/forget') {
    if (a != null) {
      router.push('/')
      // Message.error('您已处于登录状态,无法进入此页面');
    }
  }
  if (to.path == '/enroll') {
    if (a != null) {
      router.push('/')
      // Message.error('您已处于登录状态,无法进入此页面');
    }
  }
  if (a == undefined) {
    // console.log('没有token');
    // VueRouter.push('/login');
  } else {
    if (to.path == "/login") {
      // console.log('禁止通行');
      router.push('/')
      return
    } else {
      // console.log('已经通行');
      // next()
    }
  }
})
// 修改title 前置守卫
router.beforeEach((to, from, next) => {
  console.log(from.path, '数据');
  // 进入发布页likai == 1,离开时需要判定
  if(store.state.likai == 1){
    if (from.path == '/issue') {
      let a = 0
      MessageBox.confirm('您当前页面数据还未提交,确认离开当前页面?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).then(() => {
        a = 0
        next()
      }).catch((err) => {
        a = 1
      });
      if(a == 0){
        return
      }
    }
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // 如果meta等于'',就从store取值
  if (to.meta.title == '') {
    document.title = store.state.yeming
  }
  next()
})


export default router
