// 跳转方法封装 
// 需要token
import VueRouter from "vue-router";
import Vue from "vue";
import router from "../router";
import store from '@/store'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import Cookies from 'js-cookie'
// a 字符窜 路径
// b 对象(需要拼接的内容)
function jumps(a, b) {
    console.log(a,b)
    store.state.loginMessage.paths = a
    store.state.loginMessage.oldpaths = router.history.current.path.replace('/', '')
    let c = '/' + a
    if (c == '//') {
        c = '/'
    }
    let d = {}
    Object.keys(b).forEach(key => {
        // console.log(key, b[key]);
        if (c != '/renewal') {
            if (b[key] != '') {
                if (key == 'classifNum') {
                    return
                } else if (key == 'KindNum') {
                    return
                } else if (key == 'oldpaths') {// 这层用不到
                    return
                } else if (key == 'paths') { // 这层用不到
                    return
                } else {
                    let names = key
                    let value = b[key]
                    d[names] = value
                }
            }

        } else {
            // 等于renewal页面时才需要那些值
            if (b[key] != '') {
                if (key == 'oldpaths') {// 这层用不到
                    return
                } else if (key == 'paths') {// 这层用不到
                    return
                } else {
                    let names = key
                    let value = b[key]
                    d[names] = value
                }

            }
        }

    });
    // window.localStorage.setItem('sendebang', a)
    let aaa = window.localStorage.getItem('sendebang')
    // window.localStorage.removeItem('sendebang')
    if (aaa == undefined) {
        MessageBox.confirm('当前未登录,是否转到登录页!', '系统提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }
        ).then(() => {
            router.push({
                path: '/login',
                query: {}
            })
        }).catch((err) => {

        });
        return
    }
    // localStorage.setItem('paths',store.state.loginMessage.pat.cardsalt)
    // localStorage.setItem('id',store.state.loginMessage.id)hs)
    // localStorage.setItem('cardsalt',store.state.loginMessage
    router.push({
        path: c,
        query: d
    })
}
export default {
    jumps
}