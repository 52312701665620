import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 公共css样式
import './style/index.css'
// 引入封装的跳转方法
import jump from './jump/index'
// 引入封装的跳转方法(需要token)
import jumps from './jumps/index'
// 引入判断是否有token
import verification from './verification/index'
// 引入全部封装的方法
import jumpDel from './jumpDel/index'

// 视频播放组件
import VideoPlayer from 'vue-video-player/src';
// 全局注册监听本地存储

import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import {Base64} from 'js-base64'
Vue.prototype.$Base64 = Base64;

// 全局注册
Vue.prototype.jump = jump.jump;
Vue.prototype.jumps = jumps.jumps;
Vue.prototype.jumpDel = jumpDel.jumpDel;
Vue.prototype.verification = verification.verification;

Vue.use(VideoPlayer)
Vue.config.productionTip = false
Vue.use(ElementUI);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
