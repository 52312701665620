import Vue from 'vue'
import Vuex from 'vuex'
import { Notification, MessageBox, Message, Loading } from 'element-ui'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginMessage:{ //登录信息
      cardsalt:'',//帖子详情
      paths:'',//当前页面路径
      id:'',
      bm:'',//部门id
      wid:'',//文章id
      // bmid:'',//热点单位里的部门id
      oldpaths:'',//过去的地址
      key:'',//网信和网安
      price:0,//首页顶部选值
      listkey:3,//类别:0=咨询,1=投诉,2=建议,3=全部
      compile:'',//编辑id
      EditStatus:0,//编辑状态
      classifNum:0,//列表页如何搜素帖子
      KindNum:1,//列表页如何搜素帖子
      distinguish:0,//区分是哪里进入的
      yaer:0,//热门单位是否为今年0否 1是
      tab:'',
    },
    mytie:{},//我的发帖保存
    buarr:[],//详情里的部门
    pvide:0,//判断发布页视频那种上传方式
    likai:0,//发布页的离开值
    price:0,//顶部值监听
    yeming:'',//页签的前缀或者名字
    classifNum:0,
    openname:'',//连接里的名称
    shezhi:0,//跳向个人管理里的设置
    videImg:[],//图片和视频列表
    leiixng:0, // 诉求类型
    mobile:'',// 手机号
    path:'',//登录后跳转
    saomaUser:null,//扫码或者手机号登录未注册的情况下,先将登录信息存起来
    cert:'',//扫码后,没有手机号,绑定手机号时需要的凭证
    client_id:'',//会员等时绑定信息
    chatype:'',//特殊类型查找 类别:0=咨询,1=投诉,2=建议,3=文明实践4=啄木鸟平台.5=单位
    danweiid:'',//特殊类型查找之单位id
    bianji:0,//编辑状态,0未打开,1打卡
    bianjiId:'',//编辑id
    huiyaun:null,//会员信息
    sys:0,//系统消息
    zhut:0,//主题消息
    sz:0,//主题下收到的赞
    sf:0,//主题下收到的回复
    fs:0,//主题下粉丝
    wt:0,//从审核未通过的帖子和隐藏的帖子,跳入详情页  
    uploadurls:'https://wlfyradmin.wlfyr.com', // 正式
    uploadurl:'/pc',//代理
    // uploadurls:'https://yanduadmin.sendebang.com', // 开始
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
