// app.vue页面专用接口
import request from '../request'

export default class Heads {
    /**
* 会员基本信息
* @param {*} params 
* @returns 
*/
    static user() {
        return request.get("/api/user/index")
    }
    /**
* 消息个数
* @param {*} params 
* @returns 
*/
    static messageCount() {
        return request.get("/api/v1.messagecenter/messageCount")
    }
    /**
 * 会员登录绑定信息
 * @param {*} params
 * @returns
 */
    static sendChat(data) {
        return request.post('/api/chat/send', data)
    }
    /**
* 会员登录绑定信息
* @param {*} params
* @returns
*/
    static logout(data) {
        return request.post('/api/auth/logout', data)
    }
}